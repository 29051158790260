import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import { Skeleton } from '@material-ui/lab';

import LoyaltyMediaForm from 'components/loyalty-card/create-media';

import { getPassesById } from 'state/selectors';

import { ALL_CARDS_ROUTE, UPDATE_MEDIA_ROUTE } from 'constants/navigation';
import { forceWait } from 'apputil/retry-promise';

import { useNoticationContext } from 'contexts/notification-context';
import { useStateContext } from 'contexts/state-context';

import { Grid } from '@material-ui/core';

import useStyles from './styles';

const ColourAndImage = ({ passId }) => {
  const [loading, setLoading] = useState(true);
  const [pass, setPass] = useState({});
  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();
  const styles = useStyles();

  useEffect(() => {
    const onFindPass = async () => {
      const { name = '', state } = await getPassesById(passId);
      if (state === 'active') {
        navigate(`${UPDATE_MEDIA_ROUTE}?passId=${passId}`);
      }
      setPass({ passId, name });
      setLoading(false);
    };
    onFindPass();

    // eslint-disable-next-line
  }, []);

  return loading ? (
    <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
     >
    <Grid item xs={12} sm={12} md={10} xl={8}>
      <Skeleton
        animation="wave"
        component="div"
        height={750}
        className={styles.mediaSkeleton}
        variant="rect"
        />
      </Grid>
    </Grid>
  ) : (<LoyaltyMediaForm
            pass={pass}
            saveForm={async (data) => {
              const getCreatePassService = await import('services/passes/create-pass-service');
              const { createLoyaltyPassMedia } = getCreatePassService.default({
                stateContext,
                noticationContext,
              });
              await createLoyaltyPassMedia({ passMedia: data, passId });
              navigate(ALL_CARDS_ROUTE);
              await forceWait({ time: 100 });
            }}/>);
};

ColourAndImage.propTypes = { passId: string.isRequired };

export default ColourAndImage;
